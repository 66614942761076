// export default Home;
import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  InferGetServerSidePropsType,
} from "next";
import { readCMSDataClientSide } from "helpers/api";
import { CMSPage, getCMSProps } from "../wagtail.config";
import { WagtailPageProps, WagtailPageDetail, GetCMSPropsOptions } from "nextjs-wagtail";
import { MenuData, PageFragment } from "interfaces";

export default CMSPage;

export async function getNavData(): Promise<MenuData[]> {
  return readCMSDataClientSide("/api/menus/", {});
}

export async function fetchCmsData<T extends WagtailPageDetail>(
  context: GetServerSidePropsContext,
  options?: GetCMSPropsOptions
): Promise<GetServerSidePropsResult<EmtekPageProps<T>>> {
  const [pageProps, navData] = await Promise.all([getCMSProps(context, options), getNavData()]);

  // Forward cache control headers from the API
  if (pageProps.apiResponse) {
    const apiResponse = pageProps.apiResponse;
    const forwardedHeaders = ["Cache-Control", "Expires", "ETag", "Vary"];
    for (const name of forwardedHeaders) {
      const val = apiResponse.headers.get(name);
      if (val) {
        context.res.setHeader(name, val);
      }
    }
  }

  if (!("props" in pageProps)) {
    return pageProps;
  }

  // Josh needs to fix some typescript types in nextjs-wagtail before this any can go away
  return {
    props: {
      menus: navData,
      ...pageProps.props,
    } as any,
  };
}

export const getServerSideProps = fetchCmsData;

export type InferGetWagtailServerSidePropsType<T> = T extends (
  context: infer C,
  props: any,
  apiResponse: Response
) => infer R
  ? InferGetServerSidePropsType<(context: C) => R>
  : never;

export type EmtekPageProps<T> = WagtailPageProps<
  T & {
    ancestors: PageFragment[]; // for breadcrumbs
  }
> & {
  menus: MenuData[];
  // promo: PromoBarData;
};
